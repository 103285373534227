import React from "react";
import { EBusinessLabels } from "shared/models/enums/business-labels.enum";

import { IPropertyCardComponent } from "shared/models/interfaces/components/property-card.interface";
import { IRealestateResponseData } from "shared/models/interfaces/response/realestate.interface";

interface InjectedRealestateProps extends IPropertyCardComponent {
  className?: string;
}

export const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export function adapter(
  realestate: IRealestateResponseData
): IPropertyCardComponent {
  /**
   * En los resultados de la api falta
   *
   * La ciudad, dirección, especificar la moneda de la propiedad
   *
   */
  let data: IPropertyCardComponent = {
    image: "",
    isExclusive: false,
    city: "",
    address: "",
    street: "",
    typePropertyLabel: "Casa",
    businessTypeLabel: "Venta",
    price: "",
    parkingLots: 0,
    bathing: 0,
    bedrooms: 0,
    measures: 0,
    code: 0,
    parent_id: 0,
    sector: "",
    is_favorite: null,
    is_collection: false,
    has_trust_entity: false,
    type: "venta",
  };

  if (realestate?.main_picture) {
    data.image = realestate.main_picture.small;
  }

  if (realestate?.has_trust_entity) {
    data.has_trust_entity = realestate.has_trust_entity;
  }

  if (realestate?.is_collection) {
    data.is_collection = realestate.is_collection;
  }

  if (realestate?.arrangement === "Exclusivo") {
    data.isExclusive = true;
  }

  if (realestate?.sector) {
    data.street = realestate.sector;
  }

  if (realestate?.sector) {
    data.sector = realestate.sector;
  }

  if (realestate?.city) {
    data.city = realestate.city;
  }

  if (realestate?.realstate_type) {
    data.typePropertyLabel = realestate.realstate_type;
  }

  if (realestate?.business_type) {
    data.businessTypeLabel = realestate.business_type;
  }

  if (realestate?.price && realestate?.currency) {
    data.price =
      `${realestate.currency.symbol}` +
      moneyFormatter.format(realestate.price as any);
  }

  // if (realestate?.alternative_currency?.iso) {

  // bryan mando a hacer esto
  data.rent_price =
    `${
      realestate.alternative_currency &&
      realestate.alternative_currency.symbol &&
      realestate.alternate_price > 0
        ? realestate.alternative_currency.symbol
        : realestate.currency.symbol
    }` +
    moneyFormatter.format(
      realestate.alternate_price == 0 || realestate.alternate_price == null
        ? realestate.price
        : (realestate.alternate_price as any)
    );
  // bryan mando a hacer esto

  // }
  // [
  //   "Venta",
  //   "Alquiler",
  //   "Alquiler Amueblado",
  //   "Venta Amueblado",
  //   "Alquiler Linea Blanca",
  //   "Venta Linea Blanca",
  //   "Venta y Alquiler",
  //   "Venta y Alquiler Amueblado",
  //   "Venta y Alquiler Linea Blanca",
  // ];
  if (realestate.business_type) {
    const sale = ["Venta", "Venta Amueblado", "Venta Linea Blanca"].includes(
      realestate.business_type as any
    );

    const rent = [
      "Alquiler",
      "Alquiler Amueblado",
      "Alquiler Linea Blanca",
    ].includes(realestate.business_type as any);

    const all = [
      "Venta y Alquiler",
      "Venta y alquiler amueblado",
      "Venta y alquiler línea blanca",
    ].includes(realestate.business_type as any);

    if (sale) {
      data.type = "venta";
    } else if (rent) {
      data.type = "alquiler";
    } else if (all) {
      data.type = "venta y alquiler";
    }
  }

  if (realestate?.parking_spots) {
    data.parkingLots = realestate.parking_spots;
  }

  if (realestate?.bathrooms) {
    data.bathing = realestate.bathrooms;
  }

  if (realestate?.bedrooms) {
    data.bedrooms = realestate.bedrooms;
  }

  if (realestate?.sqm_construction || realestate?.sqm_land) {
    (data.measures as any) =
      realestate?.sqm_construction ?? realestate?.sqm_land;
  }

  if (realestate?.id) {
    data.code = realestate.id;
  }

  if (realestate.is_favorite !== null) {
    data.is_favorite = !!realestate.is_favorite;
  }

  if (realestate.parent_id) {
    data.parent_id = realestate.parent_id;
  }

  if (
    realestate?.project?.maximum_price &&
    realestate?.project?.minimum_price
  ) {
    data.minimum_price =
      `${realestate.currency.symbol}` +
      moneyFormatter.format(realestate.project.minimum_price as any);
    data.maximum_price =
      `${realestate.currency.symbol}` +
      moneyFormatter.format(realestate.project.maximum_price as any);
  }

  return data;
}

export default function withAdapter(
  Component: React.ComponentType<InjectedRealestateProps>
) {
  // eslint-disable-next-line react/display-name
  return class extends React.Component<any> {
    realestate: IPropertyCardComponent;
    constructor(props: any) {
      super(props);

      this.realestate = adapter(props);
    }

    render() {
      return (
        <Component {...this.realestate} className={this.props.className} />
      );
    }
  };
}
